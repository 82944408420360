.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}

.scrollable-container {
  max-height: 300px;
  overflow-y: auto;
}

.custom-close-btn {
  background: none;
  border: none;
  color: red;
  font-size: 1.5rem;
  cursor: pointer;
}

.custom-close-btn:hover {
  color: darkred;
  transform: scale(1.2);
}
