.mobile-block {
  display: none;
}

@media (max-width: 1200px) {
  /* tablet devices */
}
@media (max-width: 992px) {
  /* small tablet devices */
}
@media all and (max-width: 768px) {
  /* mobile devices */
  .section-header .logo {
    max-height: 36px;
    width: auto;
  }

  .navbar-expand .navbar-collapse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .gallery-wrap .thumbs-wrap {
    margin-bottom: 1rem;
    white-space: nowrap;
    overflow-x: auto;
  }

  .mobile-order-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .mobile-order-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .mobile-order-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .mobile-order-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}